/**
 *
 * ScrollAnchor
 * Generic class to have a smooth scroll when going to an anchor
 *
 * @author sdi
 */

/**
 *
 * HTML minimal example template
 *
 * a[href="#anchor"]
 * ...
 * #anchor
 */

class ScrollAnchor {

  /**
   *
   * Constructor
   *
   * @param options Object settings
   */

  constructor(options = {}) {

    const defaults = {
      container: '#body',
      cta: 'a[href^="#"]:not([href="#"])',
      mode: 'easeInOutQuad', // animation type
      delay: 300 // animation duration
    };

    this.settings = $.extend({}, defaults, options); // fusionne les options renseignees avec celles par defaut pour creer l'objet settings

    this.bindUIActions(); // evenements par utilisateur ex scroll hover clic touch
  }

  /**
   *
   * Scroll Action
   *
   */

  scroll(target) {
    const self = this;

    const targetScroll = $(target).offset().top;
    $('html, body').animate({ // html pour firefox, body pour chrome
      scrollTop: targetScroll
    }, self.settings.delay, self.settings.mode, function () {
      $(target).focus(); // mettre un tabindex="-1" sur la cible si c'est un element qui ne recoit pas le focus par defaut
    });
  }

  /**
   *
   * Bind UI Actions
   *
   */

  bindUIActions() {
    const self = this;
    $(self.settings.container).on('click', self.settings.cta, function (event) {
      event.preventDefault();
      const target = $(this).attr('href');
      self.scroll(target);
      return false;
    });
  }


}


module.exports = ScrollAnchor;
