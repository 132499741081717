'use strict';

/**
*
* Global helper functions
* They can be called from any JS Class, provided they are imported
*
* @author mha
*/

const helpers = {
    // get viewport size, without scrollbar
    // to call it from anywhere else than here : global.helpers.viewport() (ex :  global.helpers.viewport().width )
    viewport: function () {
        var e = window, a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return {
            width: e[ a + 'Width' ],
            height: e[ a + 'Height' ]
        };
    },
    
    // put the same min-height on several elements, based on the highest one
    // to call it from anywhere else than here : global.helpers.harmonizeHeight(selector);
    harmonizeHeight: function(selector, includePadding = true) {        
        var targetH = 0;
        helpers.resetHeight(selector);
        $(selector).each(function () {
            var elem = $(this);
            var h = $(elem).height();
            if (includePadding === true) {
                h = $(elem).outerHeight();
            }
            if (h > targetH) {
                targetH = h;
            }
        });
        targetH = Math.ceil(targetH);
        $(selector).css('min-height', targetH + 'px');
    },
    
    // reset the min-height on several elements (usually used in opposite to harmonizeHeight)
    // to call it from anywhere else than here : global.helpers.resetHeight(selector);
    resetHeight: function(selector) {
        $(selector).css('min-height', '');
    },
    
    // resize and center image in its container so it occupies the whole space
    // selector is the class name of the container of the img (ex : .media-block-news)
    // to call it from anywhere else than here : global.helpers.resizeImg(selector);
    resizeImg: function(selector) {         
        $(selector).each(function () {
            var elem = $(this);
            $(elem).imagesLoaded(function () {
                var img = $(elem).find('img');
                var wContainer = $(elem).width() + parseInt($(elem).css('padding-left')) + parseInt($(elem).css('padding-right'));
                var hContainer = $(elem).height() + parseInt($(elem).css('padding-top')) + parseInt($(elem).css('padding-bottom'));
                var ratioContainer = wContainer / hContainer;
                var ratioImg = $(img).width() / $(img).height();
                var gap = 0;
                 // if the image is larger than the container
                if (ratioImg > ratioContainer) {
                    // resize thanks to CSS classes
                    $(img).removeClass('full-width');
                    $(img).addClass('full-height');
                    // center the image
                    gap = (wContainer - $(img).width()) / 2;
                    $(img).css({
                        'margin-top': '',
                        'margin-left': gap
                    });
                // if the image is, instead, higher than the container
                } else {
                    // resize thanks to CSS classes
                    $(img).removeClass('full-height');
                    $(img).addClass('full-width');
                    // center the image
                    gap = (hContainer - $(img).height()) / 2;
                    $(img).css({
                        'margin-left': '',
                        'margin-top': gap
                    });
                }
                $(img).removeClass('faded');
            });
        });
    }
};

module.exports = helpers;
