'use strict';

import store from './_store';
import helpers from './_helpers';

/*global Modernizr */
import ResponsiveDebug from './class/ResponsiveDebug';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import Tab from './class/Tab';
import ClearInput from './class/ClearInput';


/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
  init: function () {

    this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();

    // Listener example
    /*$( "#body" ).on( "messageDisplayed", function( event, id ) {
     console.log("Message displayed : " + id);
     });*/
    let self = this;

    // Instanciate class
    //let exemple = new Exemple("Hello World");

    /*--- responsive-debug ---*/
    let responsiveDebug = new ResponsiveDebug();

    /*--- Skip links ---*/
    let skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'is-focused',
      optionHover: true
    });

    /*--- boutton header ---*/
    let burgerMeal = new Collapsible({
        container: '.header',
        cta: '.trigger-menu-header',
        optionClose: true, // there is a button to close it
        ctaClose: '.menu .menu-item a', // only if optionClose = true
        optionEsc: true,
        optionCloseOnBody: true // to close all when click somewhere else,
    });
    /*--- collapsible intro ---*/
    let langage = new Collapsible({
        container: '.langage',
        cta: '.trigger-langage',
        optionEsc: true,
        optionCloseOnBody: true // to close all when click somewhere else,
    });

    /*--- collapsible intro ---*/
    let intro = new Collapsible({
        container: '.intro',
        cta: '.cta-intro',
        optionScrollTop: true

    });

    /*--- collapsible produits ---*/
    let product = new Collapsible({
        container: '.product',
        cta: '.cta-product',
        optionScrollTop: true
    });

    /*--- collapsible boutiques ---*/
    let shop = new Collapsible({
        container: '.store',
        cta: '.cta-store',
        optionScrollTop: true
    });

    /*--- collapsible adresses pays ---*/
    let country = new Collapsible({
        container: '.item-location',
        cta: '.top-country',
        className: 'is-active'
    });
    /*--- collapsible adresses pays ---*/
    let writeUs = new Collapsible({
        container: '.write',
        cta: '.cta-write',
        optionClose: true, // there is a button to close it
        ctaClose: '.cta-close-popin-form-write', // only if optionClose = true
        optionEsc: true,
        optionCloseOnBody: true // to close all when click somewhere else
    });

    /*--- colorbox ---*/
    // let colorbox = new Colorbox({});
    /*--- animation scroll ---*/
    let scrollAnchor = new ScrollAnchor();

    /*--- clear input ---*/
    let clearInput = new ClearInput();

    // responsive
    self.onResize();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    let self = this;
  },

  onScroll: function () {
    let self = this;
  },

  bindUI: function () {
    let self = this;

    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }

    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      self.onScroll();
    });



    /*--- Meganemu focus-in/out : close  ---*/
    /*$('body').on('focusin','#mega-menu',function (event) {
      $('body').addClass('mega-menu-is-opened');
    });
    $('body').on('focusout','#mega-menu',function (event) {
      $('body').removeClass('mega-menu-is-opened');
    });*/

    /*--- Cookies banner : close  ---*/
    $('body').on('click','.cookies-banner .btn',function (event) {
      $('.cookies-banner').animate({height:'hide', paddingTop: 0, paddingBottom: 0}, 350, function () {
        $('.cookies-banner').remove();
      });
    });
  }
};

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/
};

app.init();

// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;
